export const environment = {
  production: false,
  appVersion: require('../../package.json').version,
  firebaseConfig: {
    apiKey: 'AIzaSyBWFP6BbJB7D70EZtAO1JbtOAnLpdbztHE',
    authDomain: 'alertnetworks-net-dev.firebaseapp.com',
    databaseURL: 'https://alertnetworks-net-dev-default-rtdb.firebaseio.com',
    projectId: 'alertnetworks-net-dev',
    storageBucket: 'alertnetworks-net-dev.appspot.com',
    messagingSenderId: '1096923450826',
    appId: '1:1096923450826:web:2fd2e817c3e5a0bf021333',
    measurementId: 'G-EVLPMKV4TS',
  },
  urls: {
    api: 'https://api.dev.alertnetworks.net/v1',
    marketData: 'https://alertnetworks.net/wordpress',
    glossary: 'https://alertnetworks.net/wordpress/glossary-of-fire-terminology/',
    marketMapImage: 'https://alertnetworks.net/images/markets/',
    universalMap: 'https://www.google.com/maps/search/?api=1&query=',
    listen: 'https://player.dev.alertnetworks.net/home?job=all',
    sentryDsn: 'https://332bd3074f284a4bbd8241fb6de9dc1a@sentry.alertnetworks.net/10',
    joinOurNetwork: 'https://alertnetworks.net/join',
  },
  fromEmail: 'no-reply@alertnetworks.net',
  toEmail: 'team@alertnetworks.net',
};
